import {SET_NOTIFICATIONS, SET_TOPIC_NOTIFICATIONS} from './types';

function setNotifications(notifications) {
  return (dispatch) => {
    dispatch({type: SET_NOTIFICATIONS, payload: notifications});
  };
}

function setTopicNotifications(notifications) {
  return (dispatch) => {
    dispatch({type: SET_TOPIC_NOTIFICATIONS, payload: notifications});
  };
}

export {setNotifications, setTopicNotifications};
