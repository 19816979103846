import React from "react"
import * as Icon from "react-feather"
import config from "./../config";

const navigationConfig = [
  {
    id: "Home",
    title: "Home",
    type: "external-link",
    icon: <Icon.Home size={20} color="#0e0aeb"/>,
    navLink: config.home_url
  },
  {
    id: "feeds",
    title: "Feeds",
    type: "item",
    icon: <Icon.Rss size={20} color="#85cb1f"/>,
    badge: "warning",
    navLink: "/feeds"
  },
  {
    id: "dashboard",
    title: "Dashboard",
    // type: "collapse",
    icon: <Icon.Grid size={20}  color="#b448b5" />,
    badge: "warning",
  },
  {
    id: "tutor-dashboard",
    title: "Tutor Dashboard",
    type: "item",
    icon: <Icon.User size={20} color="#1f0f3d"/>,
    badge: "warning",
    navLink: "/tutor-dashboard"
  },
  {
    type: "groupHeader",
    groupTitle: "MODULES"
  },
  {
    id: "educatio_experience",
    title: "My Skills",
    type: "collapse",
    icon: <Icon.Clock size={20} color="#9c8cfc" />,
    children: [
      {
        id: "my_subjects",
        title: "My Subjects",
        type: "item",
        icon: <Icon.Circle size={12}  />,
        permissions: ["admin", "editor"],
        navLink: "/subjects"
      },
      {
        id: "my_education",
        title: "My Education",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/education"
      },
      {
        id: "my_experience",
        title: "My Experience",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/experience"
      }
    ]
  },
  {
   id: "calendar",
   title: "Calendar",
   type: "item",
   icon: <Icon.Calendar size={20} color="#32ad2b"/>,
   permissions: ["admin", "editor"],
   navLink: "/calendar"
  },
  {
   id: "calendar",
   title: "My Appointments",
   type: "item",
   icon: <Icon.Airplay size={20} color="#ff9f43" />,
   permissions: ["admin", "editor"],
   navLink: "/my-appointments"
  },
  {
  id: "topics",
    title: "Topics",
    type: "collapse",
    icon: <Icon.Server size={20} color="#9c8cfc" />,
    children: [
      {
        id: "my_topics",
        title: "My Answers",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/topics"
      },
       {
        id: "fav_topics",
        title: "My Favorite Topics",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin", "editor"],
        navLink: "/my-favirote-topics"
      },
    ]
  },
  {
    id: "invite_students",
    title: "Invite Students",
    type: "item",
    icon: <Icon.Send size={20} color="#32ad2b"/>,
    permissions: ["admin", "editor"],
    navLink: "/invitations"
  },

  {
    id: "notifications",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={20} color="#0e0aeb"/>,
    permissions: ["admin", "editor"],
    navLink: "/notifications"
  },
  {
    id: "Logout",
    title: "Logout",
    icon: <Icon.LogOut size={20} color="#ff9f43"/>,
    type: "external-link",
    permissions: ["admin", "editor"],
    navLink: "/authorize?log_out=true"
  }
]

export default navigationConfig
